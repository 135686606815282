function getHeyflowNavId(realId) {
   const navId = Object.keys(window.heyflow).filter(id => id.indexOf(realId) === 0);
   
   if (!navId[0]) console.error('Can not find Heyflow id');
   
   return navId[0];
}

function getElementRoot(realId) {
    const embeddedElement = document.querySelector(`[flow-id="${realId}"]`);
    
    return embeddedElement ? embeddedElement.shadowRoot : document;
}

function getCriteriaKeys(formValues, prefix) {
    return Object.keys(formValues).filter(fieldName => fieldName.indexOf(prefix) === 0);
}

let valuesHistory = {};
let checkInterval;
let noFill = false;

function handleRedirect({ event, prefix, totalCount, yesCount, value }) {
    checkInterval = setInterval(() => {
        const criteriaKeys = getCriteriaKeys(valuesHistory, prefix);
        const checkedCriteria = criteriaKeys.filter(fieldName => valuesHistory[fieldName] === 'Yes');
        
        if (criteriaKeys.length < totalCount) return;
        else clearInterval(checkInterval);
        console.log('createria', valuesHistory, checkedCriteria)
        if (checkedCriteria.length < yesCount) {
            noFill = true;
            const realId = event.detail.flowID;
            const navId = getHeyflowNavId(realId);
            const elementRoot = getElementRoot(realId);
            const pageError = elementRoot.querySelector('section[name="sorry-screen"]');
            window.heyflow[navId].navigation.navigate('go', pageError.id, null, { noValidate: true });
            
            fillFinalInput({ 
                event, 
                selector: 'input[data-label="Quiz result"]', 
                value: 'Unsuccessful result',
                force: true
            });
        } else {
            noFill = false;
            fillFinalInput({ 
                event, 
                selector: 'input[data-label="Quiz result"]', 
                value: value,
                force: true
            });
        }
    }, 50)

}

function fillFinalInput({ event, selector, value, force }) {
    setTimeout(() => {
        if (noFill && !force) return;
        
        const realId = event.detail.flowID;
        const elementRoot = getElementRoot(realId);
        const targetInput = elementRoot.querySelector(selector);
        
        if (targetInput) {
            targetInput.value = value;
            console.log('FILLED');
        } else {
            console.log('NO TARGET INPUT');
        }
    }, 100);

}

window.addEventListener('heyflow-change', (event) => {
    console.log('heyflow-change', event.detail.fieldsSimple)
    valuesHistory = event.detail.fieldsSimple || {};
});

window.addEventListener('heyflow-screen-view', (event) => {
    console.log('AAAAAAA', event.detail.screenName)
    if (event.detail.screenName === 'eb1a-do-you-have-any-visa-status-in-us') {
        console.log('AAAAAAA', 'eb1a-do-you-have-any-visa-status-in-us')
        handleRedirect({ event, prefix: 'eb1a_criteria', totalCount: 10, yesCount: 3, value: 'EB1A success' });
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="EB1A result"]', 
            value: 'EB1A success'
        });
    }
    if (event.detail.screenName === 'eb1b-do-you-have-any-visa-status-in-us') {
        handleRedirect({ event, prefix: 'eb1b_criteria', totalCount: 6, yesCount: 2, value: 'EB1B success' });
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="EB1B result"]', 
            value: 'EB1B success'
        });
    }
    if (event.detail.screenName === 'eb1c') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="EB1C result"]', 
            value: 'EB1C success'
        });
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="Quiz result"]', 
            value: 'EB1C success'
        });
    }
    if (event.detail.screenName === 'sorry-screen') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="Quiz result"]', 
            value: 'Unsuccessful result'
        });
    }
});


function autoSubmit(screenName) {
    const devOrigin = "https://app.heyflow.com";
    const screenNames = ['sorry-screen', 'eb1a', 'eb1b', 'eb1c'];
    if (screenNames.includes(screenName) && window.location.origin !== devOrigin) {
        const buttonText = 'submit-heyflow-quiz-result';
        const xpath = `//section[@name='${screenName}']//button[contains(., '${buttonText}')]`
        const button = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null ).iterateNext();
        if(button instanceof HTMLButtonElement) {
            const container = document.querySelector(`section[name="${screenName}"]`);
            setTimeout(() => {
                const newButton = button.cloneNode(true);
                newButton.style.opacity = 0;
                container.appendChild(newButton);
                newButton.click();
                setTimeout(() => {
                    container.removeChild(newButton);
                }, 5000)
            }, 1500)
        } 
    }
    
}
function autoSubmitListener(event) {
    autoSubmit(event.detail.screenName)
}
window.addEventListener('heyflow-screen-view', autoSubmitListener);

function isDev() {
  const devOrigin = "https://app.heyflow.com";
  return window.location.origin === devOrigin;
}

function customAsyleeRedirect(event) {
  const value = valuesHistory["What is your current immigration status?"];
  const flowID = event.detail.flowID;
  const screenName = event.detail.screenName;
  const prefix = screenName.split("-")[0]; // eb1a or eb1c or eb1c
  if (
    value === "Asylee" &&
    screenName === `${prefix}-visa-list-dropdown` &&
    !isDev()
  ) {
    const nextPage = document.querySelector(
      `section[name="${prefix}-asylee-status-details"]`,
    );
    setTimeout(() => {
      window.heyflow[flowID].navigation.navigate("go", nextPage.id, null, {
        noValidate: true,
      });
    }, 0);
  }
}

window.addEventListener("heyflow-screen-leave", customAsyleeRedirect);

function customJ1Redirect(event) {
  const value = valuesHistory["What is your current immigration status?"];
  const flowID = event.detail.flowID;
  const screenName = event.detail.screenName;
  const prefix = screenName.split("-")[0]; // eb1a or eb1c or eb1c
  if (
    value === "J" &&
    screenName === `${prefix}-visa-list-dropdown` &&
    !isDev()
  ) {
    const nextPage = document.querySelector(
      `section[name="${prefix}-j1-status-details"]`,
    );
    setTimeout(() => {
      window.heyflow[flowID].navigation.navigate("go", nextPage.id, null, {
        noValidate: true,
      });
    }, 0);
  }
}

window.addEventListener("heyflow-screen-leave", customJ1Redirect);
